<template>
  <UContainer class="flex flex-col gap-10">
    <HomeSlide />
    <LazyHomeBlocksCoursesPopular />
    <LazyHomeBlocksInterviewQuestions
      :questions-count="questionsCount.data.value || FALLBACK_QUESTION_COUNT"
    />
    <LazyHomeBlocksKungfuPost
      :trending="postsTrending.data?.value?.data || []"
      :latest="take(posts.data.value?.items || [], DISPLAY_LATEST_NEWS)"
      :tags="topicPopulars.data.value?.data || []"
      :authors="take(topAuthors.data.value?.data || [], DISPLAY_AUTHOR_ITEMS)"
    />
    <LazyHomeBlocksJavascriptCourse />
    <LazyHomeBlocksTestimonials />
  </UContainer>
</template>

<script lang="ts" setup>
import { take } from "@techmely/utils";
import { defaultSeoOgImage } from "features/seo";
import { typesafeI18n } from "~/locales/i18n-vue";

import {
  getPostTrending,
  getPosts,
  getTopAuthors,
  getTopicPopulars,
} from "~/features/post";
import { getCountQuestions } from "~/features/question";

definePageMeta({
  layout: "page",
});
const DISPLAY_LATEST_NEWS = 5;
const DISPLAY_AUTHOR_ITEMS = 3;
const FALLBACK_QUESTION_COUNT = 5000;

const { LL } = typesafeI18n();
const [
  postsTrending,
  posts,
  topicPopulars,
  topAuthors,
  questionsCount,
] = await Promise.all([
  getPostTrending(),
  getPosts({ page: 1, size: 5 }),
  getTopicPopulars(),
  getTopAuthors(),
  getCountQuestions(),
]);

const title = LL.value.seo_home_title();
const description = LL.value.seo_home_description();
const meta: SeoMeta = {
  title,
  description,
  ...defaultSeoOgImage,
};

useSeoMeta(meta);
</script>
